import { useTranslation } from 'next-i18next';

import { getPartnerLogos, PartnerLogo } from '~/src/lib/getPartnerLogos';
import serverSideTranslationsWithLogging from '~/src/utils/serverSideTranslationsWithLogging';

import ErrorPage from '../components/organisms/ErrorPage';
import getLocalesData from '../lib/getLocalesData';
import { DropdownLink } from '../types/eventPageTypes';
import { TopNavigationMenu } from '../types/homePageTypes';
import getTopNavigationMenu from '../utils/getTopNavigationMenu';
interface Custom404Pros {
  otherLanguagesUrls: DropdownLink[];
  topNavigationMenu: TopNavigationMenu;
  partnerLogos: PartnerLogo[];
}
const Custom404 = ({ otherLanguagesUrls, topNavigationMenu, partnerLogos }: Custom404Pros) => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      partnerLogos={partnerLogos}
      otherLanguagesUrls={otherLanguagesUrls}
      title={t('common:404-message')}
      topNavigationMenu={topNavigationMenu}
    />
  );
};
export async function getStaticProps(context) {
  return {
    props: {
      ...(await serverSideTranslationsWithLogging(context.locale, [
        'common',
        'header',
        'footer',
        'session',
        'validation'
      ])),
      otherLanguagesUrls: await getLocalesData(context.locales, '404', context.locale),
      partnerLogos: await getPartnerLogos(),
      topNavigationMenu: await getTopNavigationMenu(context.locale)
    }
  };
}

export default Custom404;
